<template>
  <div>
    <h1>Vista pruebas / por favor salir de esta vista</h1>
    <!-- <table>
      <tr>
        <th>User id</th>
        <th>id</th>
        <th>GERENCIA</th>
        <th>ENPS</th>
      </tr>
      <tr
        v-for="answer in testResult.sort((a, b) =>
          a.ENPS < b.ENPS ? -1 : a.ENPS > b.ENPS ? 1 : 0,
        )"
        :key="answer.id"
      >
        <td>{{ answer.userId }}</td>
        <td>{{ answer.id }}</td>
        <td>{{ answer.GERENCIA }}</td>
        <td>{{ answer.ENPS }}</td>
      </tr>
    </table>

    <table>
      <tr v-for="answer in testResult2" :key="answer.id">
        <td>{{ answer.id }}</td>
        <td>
          {{
            new Date(answer.createdAt.seconds * 1000).toLocaleString('sp', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            })
          }}
        </td>
        <td>
          {{
            answer.openAnswers.map((answ) => [answ.question.text, answ.answer])
          }}
        </td>
      </tr>
    </table> -->
    <div></div>
    <!-- <button v-on:click="updateAnswers">Update answers and users</button> -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { usersCollection, answersCollection } from '../firebase';
export default {
  methods: {
    ...mapActions(['fetchAllAnswers']),
    ...mapActions('users', ['fetchUsers']),

    async updateUser(id, value) {
      await usersCollection.doc(id).update({ 'filters.ACTIVIDAD': value });
    },
    async updateAnswerFilter(surveyId, answerId, value) {
      await answersCollection(surveyId)
        .doc(answerId)
        .update({ 'features.ACTIVIDAD': value });
    },

    updateAnswers() {
      const updates = [
        { key: '2024-29234673', value: 'ADM' },
        { key: '2024-70498280', value: 'ADM' },
        { key: '2024-29594756', value: 'ADM' },
        { key: '2024-45266878', value: 'ADM' },
        { key: '2024-46839988', value: 'ADM' },
        { key: '2024-44335090', value: 'CAMPO' },
        { key: '2024-72920638', value: 'ADM' },
        { key: '2024-73613650', value: 'ADM' },
        { key: '2024-29669608', value: 'ADM' },
        { key: '2024-72849242', value: 'ADM' },
        { key: '2024-44487611', value: 'ADM' },
        { key: '2024-70868436', value: 'ADM' },
        { key: '2024-70670656', value: 'CAMPO' },
        { key: '2024-70323559', value: 'ADM' },
        { key: '2024-45506832', value: 'ADM' },
        { key: '2024-29420472', value: 'ADM' },
        { key: '2024-41105993', value: 'CAMPO' },
        { key: '2024-72144080', value: 'ADM' },
        { key: '2024-40889066', value: 'ADM' },
        { key: '2024-74887470', value: 'ADM' },
        { key: '2024-72901814', value: 'ADM' },
        { key: '2024-41904213', value: 'ADM' },
        { key: '2024-29212986', value: 'ADM' },
        { key: '2024-29511304', value: 'CAMPO' },
        { key: '2024-29554014', value: 'CAMPO' },
        { key: '2024-45655379', value: 'CAMPO' },
        { key: '2024-02786077', value: 'CAMPO' },
        { key: '2024-46052301', value: 'CAMPO' },
        { key: '2024-07807796', value: 'ADM' },
        { key: '2024-70673779', value: 'ADM' },
        { key: '2024-29318219', value: 'ADM' },
        { key: '2024-70655607', value: 'CAMPO' },
        { key: '2024-47727126', value: 'CAMPO' },
        { key: '2024-03685935', value: 'CAMPO' },
        { key: '2024-29607547', value: 'CAMPO' },
        { key: '2024-73229849', value: 'CAMPO' },
        { key: '2024-43444986', value: 'CAMPO' },
        { key: '2024-29642848', value: 'CAMPO' },
      ];

      updates.forEach(({ key, value }) => {
        let { id, surveyId, answerId } = this.users.find(
          ({ doc }) => doc === key,
        );
        if (answerId) {
          this.updateUser(id, value);
          this.updateAnswerFilter(surveyId, answerId, value);
        }
      });
      console.log('Actualizacion terminada');
    },
  },
  mounted() {
    this.fetchAllAnswers();
    this.fetchUsers();
  },

  computed: {
    ...mapState(['answers']),
    ...mapState('users', ['users']),
    /*testResult() {
      function removeDuplicatesById(array) {
        const uniqueMap = new Map();
        array.forEach((item) => {
          if (!uniqueMap.has(item.id)) {
            uniqueMap.set(item.id, item);
          }
        });
        return Array.from(uniqueMap.values());
      }

      let prev = '';
      let temp = [];
      [...this.answers]
        .sort((a, b) =>
          a.userId < b.userId ? -1 : a.userId > b.userId ? 1 : 0,
        )
        .forEach((answer) => {
          if (prev !== '' && prev.userId === answer.userId) {
            temp.push(answer);
            temp.push(prev);
          }
          prev = answer;
        });
      return removeDuplicatesById(temp).map((answer) => ({
        userId: answer.userId,
        id: answer.id,
        GERENCIA: answer.features.GERENCIA,
        ENPS: answer.enpsAnswer.answer,
      }));
    },

    testResult2() {
      function removeDuplicatesById(array) {
        const uniqueMap = new Map();
        array.forEach((item) => {
          if (!uniqueMap.has(item.id)) {
            uniqueMap.set(item.id, item);
          }
        });
        return Array.from(uniqueMap.values());
      }

      let prev = '';
      let temp = [];
      [...this.answers]
        .sort((a, b) =>
          a.userId < b.userId ? -1 : a.userId > b.userId ? 1 : 0,
        )
        .forEach((answer) => {
          if (prev !== '' && prev.userId === answer.userId) {
            temp.push(answer);
            temp.push(prev);
          }
          prev = answer;
        });
      return removeDuplicatesById(temp);
    },*/
  },
};
</script>

<style></style>
